import { library } from '@fortawesome/fontawesome-svg-core'
import { faGlobe, faCreditCard, faDice, faGlasses, faCommentAlt, faMoneyBillWave, faNewspaper, faWrench, faChartLine, faStore, faRss, faTools, faVoteYea } from '@fortawesome/free-solid-svg-icons'
library.add(faGlobe, faCreditCard, faDice, faGlasses, faCommentAlt, faMoneyBillWave, faNewspaper, faWrench, faChartLine, faStore, faRss, faTools, faVoteYea)

const categories =  [
    {
        name: 'SOUL',
        icon: 'globe', /* todo */
        color: '#3934A1', /* todo */
        dapps: [
            {
                name: 'Gitbook',
                url: 'https://soulswap.gitbook.io/', /* todo */
                description: 'GitBook is a modern documentation platform where teams can document everything from products to internal knowledge-bases and APIs.',
                icon: './images/dapps/git-book.png'
            },
            {
                name: 'GitHub',
                url: 'https://github.com/SoulSwapFinance',
                description: 'Open sourced, smart contracts deployed by SoulSwap developers.',
                icon: './images/dapps/github.png'
            },
            // {
            //     name: 'LitePaper',
            //     url: 'https://soulswap.finance/Litepaper.pdf',
            //     description: 'SOUL is designed to behave as the quintessential model for other AMM and Multichain DAOs and institutions to emulate.',
            //     icon: './images/dapps/writing.png'
            // },
            {
                name: 'Official Website',
                url: 'https://soulswap.finance',
                description: 'SOUL is designed to behave as the quintessential model for other AMM and Multichain DAOs and institutions to emulate.',
                icon: './images/dapps/website.png'
            }
        ],
    },
    {
        name: 'DAPPS',
        icon: 'money-bill-wave',
        color: '#3934A1',
        dapps: [
            {
                name: 'Stream Claim',
                url: 'https://app.soulswap.finance/streams',
                description: 'Claim your SOUL Stream',
                icon: './images/dapps/gift-box.png'
            },
            {
                name: 'Airdrop Claim',
                url: 'https://app.soulswap.finance/airdrop',
                description: 'Claim your SOUL Airdrop',
                icon: './images/dapps/gift-box.png'
            },
            {
                name: 'Liquidity Mine',
                url: 'https://app.soulswap.finance/farms',
                description: 'Provide liquidity on our exchange and recieve rewards paid out in SOUL for participating in our network.',
                icon: './images/dapps/mining.png'
            },
            {
                name: 'Soul Staking',
                url: 'https://app.soulswap.finance/stake',
                description: 'Single asset staking (SAS) DApp designed to enable users to deposit SOUL to be rewarded SOUL and tokens from newly launched projects.',
                icon: './images/dapps/mining-cart.png'
            },
        ],
    },
    {
        name: 'TRADING',
        icon: 'chart-line',
        color: '#ACA9E3',
        dapps: [
            {                
                name: 'SoulSwap DEX',
                url: 'https://app.soulswap.finance/#/swap',
                description: 'Exchange assets in a decentralized manner and provide liquidity for your share in exchange fees, all on our multi-chain decentralized exchange.',
                icon: './images/dapps/deploy.png'
            },
            {
                name: 'CoinGecko',
                // url: 'https://www.coingecko.com/en/coins/soulswap-finance',
                url: 'https://www.coingecko.com/en/coins',
                description: 'Find useful information, including price history, trends, and rank on CoinGecko.',
                icon: './images/dapps/coingecko.png'
            },
            {
                name: 'CoinMarketCap',
                // url: 'https://coinmarketcap.com/currencies/soulswap-finance',
                url: 'https://coinmarketcap.com/currencies',
                description: 'Find useful information, including market rank, price history, and trends on CMC.',
                icon: './images/dapps/coinmarketcap.com.png'
            },
            {
                name: 'Trading Room',
                url: 'https://t.me/soulswapdefi', // todo: update
                description: 'Visit the historical price and liquidity for the SOUL on Uniswap, the decentralized exchange.',
                icon: './images/dapps/telegram.png'
            },
            {                
                name: 'SoulSwap Charts',
                url: 'https://info.soulswap.finance/',
                description: 'Visit the historical price and liquidity for SOUL on our multi-chain decentralized exchange.',
                icon: './images/dapps/exchanging.png'
            }
        ],   
    },
    {
        name: 'CONTRACTS',
        icon: 'glasses',
        color: '#ACA9E3',
        dapps: [
            {
                name: 'Airdrop',
                url: 'https://ftmscan.com/address', /* todo */
                description: 'Contains the SOUL tokens ready to be claimed by the eligible recipients.',
                icon: './images/dapps/package.png'
            },
            {
                name: 'SoulToken',
                url: 'https://ftmscan.com/address', /* todo */
                description: 'The official token for SOUL, which represents your voting power and stake in the DAO Treasury.',
                icon: './images/dapps/stakeholder.png'
            },
            {
                name: 'Liquidity Mine',
                url: 'https://ftmscan.com/address', /* todo */
                description: 'Official Liquidity Mine for SoulSwap. Slow drip, fair distribution of SOUL utility tokens.',
                icon: './images/dapps/mining-cart.png'
            },
            {
                name: 'Treasury',
                url: 'https://ftmscan.com/address', /* todo */
                description: 'Treasury contract, which is a multisignatory smart contract containing our Treasury, which belongs to the SOUL Community',
                icon: './images/dapps/treasury.png'
            },
            {
                name: 'SOUL-LP SOUL-FTM LP',
                url: 'https://ftmscan.com/address', /* todo */
                description: 'The LP token that represents the SOUL-FTM pair. Liquidity providers recieve thier proportionate share of the trading fees generated from trades in the pool. This token also receieves a yield BOOST.',
                icon: './images/dapps/unicorn.png' /* todo */
            }
        ],   
    },
    {
        name: 'COMMUNITY',
        icon: 'comment-alt',
        color: '#000000',
        dapps: [
            {
                name: 'Chat on Discord',
                url: 'https://discord.gg', /* todo */
                description: 'Join in on the discussions with our Discord Community. We have Channels dedicated to topics such as speculation and trading, governance proposals, announcements, and more.',
                icon: './images/dapps/dis-cord-black.png'
            },
            {
                name: 'Chat in our Forum',
                url: 'https://forum.soulswap.finance',
                description: 'This is an open and free platform built from the ground up for the community by the community so feel free to join us in our epic journey of open and decentralized autonomous governance across multiple chains unlike ever done before.',
                icon: './images/dapps/government.png'
            },
            {
                name: 'Chat on Telegram',
                url: 'https://t.me/SoulSwapDeFi',
                description: 'Join our most active community presence on Telegram. Here you may find the quickest reply to your questions or concerns by one of our many volunteer community moderators, developers, or members.',
                icon: './images/dapps/tg.png'
            },
            // {
            //     name: 'DevProposal: Vault',
            //     url: 'https://forum.soulswap.finance/t/devproposal-vaults/',
            //     description: 'Interested in fielding some feedback on potentially building out a vault for a safe, yet profitable place to park your SOUL reward and gain rewards for your loyalty and participation.',
            //     icon: './images/dapps/safe.png'
            // },
            {
                name: 'Follow on Twitter',
                url: 'https://twitter.com/SoulSwapFinance',
                description: 'Join us on Twitter and keep up-to-date on all of our upcoming opportunities, partnerships, releases, competitions, and more exciting news on a regular basis.',
                icon: './images/dapps/twitter-black.png'
            }
        ],
    },
    {
        name: 'PRESALE',
        icon: 'credit-card',
        color: '#000000',
        dapps: [
            {
                name: 'COMMUNITY REWARDS',
                url: 'https://launch.soulswap.finance', /* todo */
                description: 'Participate in challenges for community rewards. Points add up over time. Governance handed out to those who use proof of participation to acquire a position in the protocol.',
                icon: './images/dapps/treasury.png' /* todo */
            },
            {
                name: 'DEX BETA',
                url: 'https://app.soulswap.xyz/#/swap', /* todo */
                description: 'Swap FTM (or any other token) on SoulSwap, which is the number one (by daily volume) decentralized exchange on Fantom.',
                icon: './images/dapps/logo-img.png' /* todo */
            }
        ]
    }
];

export default categories;
